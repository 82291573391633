<template>
  <section>
    <p>Matters needing attention when installing the metering pump:</p>
    <p>
      1. The dosing metering pump and the motor have an integral structure, and
      there is no need to align them during installation. So, it is very
      convenient for installation.
    </p>
    <p>
      2. Before installing the pump, please carefully check whether there are
      hard objects (such as stones, iron particles, etc.) in the pump flow path
      that will affect the operation of the pump, so as not to damage the
      impeller and pump body during the operation of the pump.
    </p>
    <p>
      3. The anchor bolts must be tightened during installation to avoid the
      impact of vibration on the pump performance when starting.
    </p>
    <p>
      4. The weight of the pipeline should not be added to the pump when the
      dosing metering pump is installed. Separate support bodies shall be
      provided to prevent deformation from affecting operation performance and
      service life.
    </p>
    <p>
      5. For convenient maintenance and safe use, it is necessary to install a
      regulating valve on each of the inlet and outlet pipelines of the pump and
      a pressure gauge near the pump outlet. This is to ensure the operation
      within the rated head and flow range. Also, this is to ensure the normal
      operation of the pump and increase its service life.
    </p>
    <p>
      6. The dosing metering pump is used for suction, so it should be equipped
      with a bottom valve. And the inlet pipeline should not have too many
      bends, and at the same time, there should be no leakage of water or air.
    </p>
    <p>
      7. If the discharge pipeline is a reverse stop, the valve should be
      installed outside the gate valve.
    </p>
    <p>
      8. After installation, please turn the pump shaft. The impeller should
      have friction or jamming, otherwise, the pump should be disassembled and
      check the cause.
    </p>
    <p>
      9. The installation method of dosing metering pumps is divided into rigid
      connection and flexible connection installation.
    </p>
    <p>Operating Procedures Of The Metering Pump:</p>
    <p>
      1. All air switches of the dosing metering pump are opened on the PLC
      cabinet to check whether the instrument is displayed on site and whether
      the liquid level display is normal. Then the pool will open quantitatively
      between the outlet valves.
    </p>
    <p>
      2. The dosing metering pump, water solenoid valve, dissolving tank mixer,
      original tank and tank connection valve switch should be switched to the
      “remote” position. The stroke speed of the metering pump is adjusted on
      site.
    </p>
    <p>3. The liquid enters the 1# to 3# dissolution tank.</p>
    <p>
      4. Then go back to the console, click to enter the “metering system”
      screen, and manually adjust the output frequency of the metering pump.
    </p>
    <p>
      5. Click the water solenoid valve icon on the screen, turn on the solenoid
      valve to start pour water into the tank to dilute the liquid storage, and
      observe the screen level gauge. When the liquid level meter reaches a
      fixed height, click the blender icon to open the mixer.
    </p>
    <p>
      6. Observe the level gauge, when it reached the required dilution of the
      liquid, close the inlet solenoid valve and turn off the mixer. Click the
      outlet valve icon of the dissolution tank to open the outlet valve, and
      the click the metering pump icon to turn on the metering pump. Observe the
      liquid level gauge and flow meter, when the liquid level is too low,
      please close the metering pump and the liquid valve.
    </p>
  </section>
</template>
<script>
export default {
  title: "Installation and Download",
}
</script>